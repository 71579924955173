import * as React from "react";
import moment from "moment";

export default function SubjectInput({
  subject_details,
  field,
  type,
  multiline,
  className,
  read_only,
}) {
  const [value, setValue] = React.useState(() => {
    if (subject_details[field]) {
      if (type === "checkbox") {
        return subject_details[field] == "true";
      }
      return subject_details[field];
    } else {
      if (type === "date" && field !== "3_5_date_of_reassessment") {
        return moment(new Date()).format("YYYY-MM-DD");
      }
      return "";
    }
  });

  const onChange = (e) => {
    if (type === "checkbox") {
      const value = e.target.checked;
      subject_details[field] = value;
      setValue(value);
      return;
    }

    if (multiline) {
      const el = e.target;
      el.style.height = "1px";
      el.style.height = 25 + el.scrollHeight + "px";
    }

    const value = e.target.value;
    subject_details[field] = value;
    setValue(value);
  };

  const renderElement = () => {
    if (multiline) {
      return (
        <textarea
          rows={1}
          className={className}
          onChange={onChange}
          style={{ overflow: "hidden" }}
          readOnly={read_only}
        >
          {value}
        </textarea>
      );
    } else {
      return (
        <input
          type={type ? type : "text"}
          className={className}
          value={value}
          checked={type === "checkbox" ? value == true : undefined}
          onChange={onChange}
          readOnly={read_only}
        />
      );
    }
  };

  return renderElement();
}
